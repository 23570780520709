import { Map as hashMap } from 'immutable';

export const selectChapter = (state: any) =>
  state.getIn(['chapter', 'current']) || hashMap();
export const selectChapterTitle = (state: any) =>
  selectChapter(state).get('title');
export const selectChapterNumber = (state: any) =>
  selectChapter(state).get('number');
export const selectChapterId = (state: any) => selectChapter(state).get('id');
export const selectChapterWindow = (state: any) =>
  state.getIn(['chapter', 'window'], hashMap());
export const selectCompletedChapterAlreadyShown = (state: any) =>
  state.getIn(['chapter', 'firstTimeCompletedChapters']) || hashMap();
export const selectIsLastChapter = (state: any) =>
  selectChapter(state).get('is_last_chapter');
