/** @jsx jsx */
import { Text } from '@datacamp/waffles/text';
import { tokens } from '@datacamp/waffles/tokens';
import { jsx } from '@emotion/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { GoalOptionType } from './PickStreakGoalScreen';

export const SelectGoalOptions: React.FC<{
  onChange: (v: GoalOptionType) => void;
  option: GoalOptionType;
  selectedGoal: GoalOptionType | null;
}> = ({ onChange, option, selectedGoal }) => {
  const { t } = useTranslation();

  const isChecked = selectedGoal?.key === option.key;

  return (
    <div
      css={{
        display: 'flex',

        '&+div': {
          borderTop: `${tokens.borderWidth.thin} solid ${tokens.colors.greyLight}`,
        },
      }}
    >
      <button
        css={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          padding: tokens.spacing.medium,
          justifyContent: 'space-between',
          borderRadius: tokens.borderRadius.medium,
          border: `${tokens.borderWidth.thin} solid ${
            isChecked ? tokens.colors.green : 'transparent'
          }`,
          background: 'none',
          margin: 0,
          outline: 'none',
          '&:focus': {
            outline: `${tokens.borderWidth.thin} solid ${
              isChecked ? tokens.colors.green : tokens.colors.blue
            }`,
          },
        }}
        onClick={() => onChange(option)}
        tabIndex={0}
        data-trackid={`campus-pick-streak-goal-${option.value}days`}
      >
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: 18,
              height: 18,
              borderRadius: tokens.borderRadius.circle,
              border: `${tokens.borderWidth.thin} solid ${
                isChecked ? tokens.colors.green : tokens.colors.grey60
              }`,
              backgroundColor: isChecked
                ? tokens.colors.green
                : tokens.colors.white,
              fontSize: tokens.fontSizes.xsmall,
            }}
          >
            {isChecked ? '✔' : null}
          </div>
          <Text
            as="label"
            css={{
              color: tokens.colors.navy,
              margin: 0,
              marginLeft: tokens.spacing.small,
              fontWeight: tokens.fontWeights.bold,
              cursor: 'pointer',
            }}
            size="large"
            htmlFor={option.key}
          >
            {option.value} {t('DailyStreakModal.pickGoal.days')}
          </Text>
        </div>
        <Text
          css={{
            color: tokens.colors.navySubtleTextOnLight,
            margin: 0,
          }}
          size="medium"
        >
          {t(`DailyStreakModal.pickGoal.${option.key}`)}
        </Text>
      </button>
    </div>
  );
};
