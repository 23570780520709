/** @jsx jsx */
import { jsx } from '@emotion/react';
import inRange from 'lodash/inRange';
import map from 'lodash/map';
import { connect } from 'react-redux';

import ExpandedGraphicalOutput from '../../components/ExpandedGraphicalOutput';
import GraphicalOutput from '../../components/GraphicalOutput';
import * as actions from '../../redux/actions';
import * as selectors from '../../redux/selectors';

import sourceFormatter from './sourceFormatter';

const GraphicalOutputs = ({ category, key, overwrittenProps = {} }: any) => {
  const mapStateToProps = (state: any) => {
    const graphics = selectors
      .selectUserExercise(state)
      .getIn([category, key, 'props']);

    if (graphics == null) {
      return {};
    }

    const currentIndex = graphics.get('currentIndex');
    const maxIndex = graphics.get('sources').count() - 1;
    const multiplexerUrl = selectors.selectMultiplexerUrl(state);
    const uiTheme = state.getIn(['settings', 'uiTheme']);
    return {
      ...sourceFormatter({
        source: graphics.getIn(['sources', currentIndex]),
        multiplexerUrl,
        isExpanded: false,
      }),
      isCurrentAlreadyExpanded: graphics.getIn([
        'sources',
        currentIndex,
        'isExpanded',
      ]),
      currentId: graphics.getIn(['sources', currentIndex, 'id']),
      currentStatus: `${currentIndex + 1}/${maxIndex + 1}`,
      currentIndex,
      maxIndex,
      uiTheme,
      language: selectors.selectExercise(state).get('language'),
      expandedGraphicalOutputs: graphics
        .get('sources')
        .map((source: any, index: any) => source.set('index', index))
        .filter((source: any) => source.get('isExpanded'))
        .map((source: any) => ({
          key: source.get('id'),
          hasFocus: source.get('expandedHasFocus'),
          index: source.get('index'),
          ...sourceFormatter({ source, multiplexerUrl, isExpanded: true }),
        }))
        .toArray(),
    };
  };

  const mapDispatchToProps = (dispatch: any) => ({
    setIndex: ({ index, language, maxIndex }: any) => {
      if (!inRange(index, maxIndex + 1)) {
        return null;
      }
      if (key === 'plot') {
        return () => {
          dispatch(
            actions.executeCode({
              language,
              figureIndex: index,
              // @ts-expect-error ts-migrate(2322) FIXME: Type '"resize"' is not assignable to type '"init" ... Remove this comment to see the full error message
              command: 'resize',
              // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'number'.
              width: null,
              // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'number'.
              height: null,
            }),
          );
        };
      }
      return () => {
        dispatch(
          actions.updateCurrentGraphicalOutput({ category, key, index }),
        );
      };
    },

    expand: (id: any) => {
      dispatch(actions.expandGraphicalOutput({ category, key, id }));
    },

    focus: (id: any) => {
      dispatch(actions.focusGraphicalOutput({ category, key, id }));
    },

    blur: (id: any) => {
      dispatch(actions.blurGraphicalOutput({ category, key, id }));
    },

    close: (id: any) => {
      dispatch(actions.closeGraphicalOutput({ category, key, id }));
    },

    executeCode: (settings: any) => {
      dispatch(actions.executeCode(settings));
    },
  });

  const mergeProps = (
    {
      currentId,
      currentIndex,
      expandedGraphicalOutputs,
      isCurrentAlreadyExpanded,
      language,
      maxIndex,
      ...props
    }: any,
    { blur, close, executeCode, expand, focus, setIndex }: any,
    { glContainer }: any,
  ) => ({
    ...props,
    onShowPrevious: setIndex({ index: currentIndex - 1, maxIndex, language }),
    onShowNext: setIndex({ index: currentIndex + 1, maxIndex, language }),
    onResize:
      key === 'plot'
        ? (width: any, height: any) => {
            executeCode({
              language,
              figureIndex: currentIndex,
              command: 'resize',
              width,
              height,
            });
          }
        : null,
    onExpand: () => {
      if (isCurrentAlreadyExpanded) {
        focus(currentId);
      } else {
        expand(currentId);
      }
    },
    glContainer,
    expandedGraphicalOutputs: map(
      expandedGraphicalOutputs,
      (expandedGraphicalOutputProps) => ({
        ...expandedGraphicalOutputProps,
        onFocus: () => focus(expandedGraphicalOutputProps.key),
        onBlur: () => blur(expandedGraphicalOutputProps.key),
        onClose: () => close(expandedGraphicalOutputProps.key),
        onResize:
          key === 'plot'
            ? (width: any, height: any) => {
                executeCode({
                  language,
                  figureIndex: expandedGraphicalOutputProps.index,
                  command: 'resize',
                  width,
                  height,
                });
              }
            : undefined,
      }),
    ),
    ...overwrittenProps,
  });

  return connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps,
  )(({ expandedGraphicalOutputs, ...graphicalOutputProps }: any) => (
    <div
      css={{
        width: '100%',
        height: '100%',
      }}
    >
      <GraphicalOutput {...graphicalOutputProps} />
      {map(expandedGraphicalOutputs, (expandedGraphicalOutputProps) => (
        <ExpandedGraphicalOutput {...expandedGraphicalOutputProps} />
      ))}
    </div>
  ));
};

export default GraphicalOutputs;
