import { isFeatureEnabled } from '../../helpers/featureFlags';
import type { Thunk } from '../../interfaces/State';
import * as selectors from '../selectors';

import * as actions from './index';

export const openCourseReviewModal =
  ({ rating }: { rating: number } = { rating: 0 }): Thunk =>
  (dispatch, getState) => {
    const state = getState();
    const isLastChapter = selectors.selectIsLastChapter(state);
    const exerciseIndex = selectors.selectExerciseIndex(state);
    const currentChapterNbExercises =
      selectors.selectCurrentChapterNbExercises(state);
    const isLastExercise = exerciseIndex === currentChapterNbExercises - 1;

    dispatch(actions.removeModal());

    if (
      isLastExercise &&
      isLastChapter &&
      rating >= 4 &&
      isFeatureEnabled('wl--course-review-by-end-of-last-chapter')
    ) {
      return dispatch(
        actions.showModal({
          modal: {
            ...selectors.MODAL_TYPE.COURSE_REVIEW,
            onFinish: () => {
              dispatch(actions.removeModal());
              dispatch(actions.nextExternalExercise());
            },
          },
        }),
      );
    }

    return dispatch(actions.nextExternalExercise());
  };
