import type { Locale } from 'date-fns';
import {
  de as deLocale,
  enUS as enUSLocale,
  es as esLocale,
  fr as frLocale,
  it as itLocale,
  pt as ptLocale,
  tr as trLocale,
} from 'date-fns/locale';
import i18n from 'i18next';
import ICU from 'i18next-icu';
import { initReactI18next } from 'react-i18next';

import commonDe from '../locales/de-DE/common.json';
import glossaryDe from '../locales/de-DE/glossary.json';
import translationDe from '../locales/de-DE/translation.json';
import commonEn from '../locales/en/common.json';
import glossaryEn from '../locales/en/glossary.json';
import translationEn from '../locales/en/translation.json';
import commonEs from '../locales/es/common.json';
import glossaryEs from '../locales/es/glossary.json';
import translationEs from '../locales/es/translation.json';
import commonFr from '../locales/fr-FR/common.json';
import glossaryFr from '../locales/fr-FR/glossary.json';
import translationFr from '../locales/fr-FR/translation.json';
import commonIt from '../locales/it-IT/common.json';
import glossaryIt from '../locales/it-IT/glossary.json';
import translationIt from '../locales/it-IT/translation.json';
import commonPt from '../locales/pt-BR/common.json';
import glossaryPt from '../locales/pt-BR/glossary.json';
import translationPt from '../locales/pt-BR/translation.json';
import commonTr from '../locales/tr-TR/common.json';
import glossaryTr from '../locales/tr-TR/glossary.json';
import translationTr from '../locales/tr-TR/translation.json';

export const I18N_PLACEHOLDER = '__placeholder__';
export const DEFAULT_LANGUAGE = 'en';
export const SUPPORTED_LANGUAGES = ['en', 'es', 'pt', 'de', 'fr', 'it', 'tr'];
export const X_DC_LANG_HEADER = 'x-dc-lang';

export const resources = {
  en: {
    common: commonEn,
    glossary: glossaryEn,
    translation: translationEn,
  },
  es: {
    common: commonEs,
    glossary: glossaryEs,
    translation: translationEs,
  },
  pt: {
    common: commonPt,
    glossary: glossaryPt,
    translation: translationPt,
  },
  de: {
    common: commonDe,
    glossary: glossaryDe,
    translation: translationDe,
  },
  fr: {
    common: commonFr,
    glossary: glossaryFr,
    translation: translationFr,
  },
  it: {
    common: commonIt,
    glossary: glossaryIt,
    translation: translationIt,
  },
  tr: {
    common: commonTr,
    glossary: glossaryTr,
    translation: translationTr,
  },
};

// eslint-disable-next-line prefer-regex-literals
export const languagePathRegex = new RegExp('/[a-z]{2}/');

export const getUrlWithLanguage = (url: string, language: string): string => {
  return language === DEFAULT_LANGUAGE ? url : `${url}/${language}`;
};

export const getLanguage = (language: string): string => {
  const languageMapping: { [key: string]: string } = {
    DEFAULT_LANGUAGE: 'en-US',
    es: 'es-ES',
    de: 'de-DE',
    pt: 'pt-BR',
    fr: 'fr-FR',
    it: 'it-IT',
    tr: 'tr-TR',
  };

  return Object.prototype.hasOwnProperty.call(languageMapping, language)
    ? languageMapping[language]
    : 'en-US';
};

export const getDateFnsLocale = (language: string): Locale => {
  switch (language) {
    case 'de':
      return deLocale;
    case 'es':
      return esLocale;
    case 'pt':
      return ptLocale;
    case 'fr':
      return frLocale;
    case 'it':
      return itLocale;
    case 'tr':
      return trLocale;
    default:
      return enUSLocale;
  }
};

export const initializeI18n = (language: string) => {
  return i18n
    .use(ICU)
    .use(initReactI18next)
    .init({
      lng: language,
      fallbackLng: DEFAULT_LANGUAGE,
      interpolation: {
        escapeValue: false,
      },
      resources,
      returnNull: false,
    });
};

export default i18n;
