/** @jsx jsx */
import { Rating } from '@datacamp/le-shared-components';
import { Dialog } from '@datacamp/waffles/dialog';
import { FormField } from '@datacamp/waffles/form-field';
import { Checkmark } from '@datacamp/waffles/icon';
import { TextArea } from '@datacamp/waffles/text-area';
import { tokens } from '@datacamp/waffles/tokens';
import { jsx } from '@emotion/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import ApiClient from '../../helpers/ApiClient';
import { getLearnApiClient } from '../../helpers/getClients';
import { useSelector } from '../../interfaces/State';
import {
  selectCourseId,
  selectCourseTitle,
  selectUserFirstName,
} from '../../redux/selectors';

type Props = {
  onFinish: (...args: any[]) => any;
};

const learnApiClient = getLearnApiClient();

const CourseReviewModal: React.FC<Props> = ({ onFinish }) => {
  const courseId = useSelector(selectCourseId);
  const courseTitle = useSelector(selectCourseTitle);
  const userFirstName = useSelector(selectUserFirstName);

  const { t } = useTranslation('translation');
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [rating, setRating] = useState<number>(0);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const submit = () => {
    setSubmitting(true);
    learnApiClient
      .execute(
        ApiClient.endpoint.submitCourseReview(courseId, {
          score: rating,
          body: feedbackMessage,
          userDisplayName: userFirstName,
        }),
      )
      .toPromise()
      .catch((_err: Error) => {
        // eslint-disable-next-line
        console.warn('Error: Failed to submit Course Review');
      })
      .finally(() => {
        onFinish();
        setSubmitting(false);
      });
  };

  const handleOnClose = () => {
    setFeedbackMessage('');
    setRating(0);
    onFinish();
  };

  return (
    <Dialog isOpen onClose={handleOnClose} alignCenter>
      <Dialog.Header mode="decorative" variant="success">
        {t('CourseReviewModal.title')}
      </Dialog.Header>
      <Dialog.Body>
        <div
          css={{
            paddingBottom: tokens.spacing.medium,
            '&>div': { backgroundColor: 'transparent' },
          }}
        >
          <Rating
            rating={rating}
            onRate={(newRating) => setRating(newRating)}
            size={24}
            wrapped
          />
        </div>
        <FormField
          label={t('CourseReviewModal.reviewFrom', {
            courseTitle,
            userFirstName,
          })}
          required
        >
          <TextArea
            value={feedbackMessage}
            onChange={(event) => {
              setFeedbackMessage(event.target.value);
            }}
            rows={4}
          />
        </FormField>
      </Dialog.Body>

      <Dialog.Footer>
        <Dialog.Button
          iconLeft={<Checkmark />}
          variant="primary"
          onClick={submit}
          disabled={rating === 0 || submitting}
          data-trackid="campus-app-submit-course-review"
        >
          {t('CourseReviewModal.feedbackButton')}
        </Dialog.Button>
      </Dialog.Footer>
    </Dialog>
  );
};

export default CourseReviewModal;
