import type { Language, SessionOutput } from '@datacamp/multiplexer-client';
import mux from '@datacamp/multiplexer-client';
import isDeepEqual from 'lodash/isEqual';
import zip from 'lodash/zip';
import raven from 'raven-js';

import type { State } from '../interfaces/State';
import * as selectors from '../redux/selectors';

import type { CodeExecutionBackend } from './codeExecutionBackend';
import prometheusClient from './prometheusClient';
import { createPyodideBackend } from './pyodideBackend';

let _client: ReturnType<typeof prometheusClient> | undefined;

const client = () => {
  if (_client == null) {
    _client = prometheusClient();
  }
  return _client;
};

const hasOutputDifferentFormattingAcrossDifferentVersions = (
  pyodideOutput: SessionOutput,
  muxOutput: SessionOutput,
): boolean => {
  const commonOutputs = [
    'Signature: ', // Ignore difference outputting function signatures
    'Docstring:', // Ignore difference outputting docstring
    'Help on built-in function ', // Ignore difference outputting function help information
    'Help on class ', // Ignore difference outputting class help information
    '<function ', // Ignore difference outputting functions
    "<module '", // Ignore difference outputting modules
    '<built-in method ', // Ignore difference printing object's built-in methods
  ];
  return (
    typeof pyodideOutput.payload === 'string' &&
    typeof muxOutput.payload === 'string' &&
    commonOutputs.some(
      (text) =>
        pyodideOutput.payload.startsWith(text) &&
        muxOutput.payload.startsWith(text),
    )
  );
};

const measureTimeToRunOrSubmitCode = ({
  environment,
  time,
}: {
  environment: 'mux' | 'wasm';
  time: number;
}) => {
  client().observeDistribution(
    'campus__pyodide_experiment_time_to_run_or_submit',
    { environment },
    time,
  );
};

const measureTimeUntilSessionReady = ({
  environment,
  time,
}: {
  environment: 'mux' | 'wasm';
  time: number;
}) => {
  client().observeDistribution(
    'campus__pyodide_experiment_time_until_session_ready',
    { environment },
    time,
  );
};

const countPyodideSessionNotReadyWhenRunningCode = () => {
  client().increment(
    'campus__pyodide_session_not_ready_when_running_code',
    {},
    1,
  );
};

const countNumberOfOutputsMismatch = () => {
  client().increment(
    'campus__pyodide_experiment_number_of_outputs_mismatch',
    {},
    1,
  );
};

const countOutputTypeMismatch = () => {
  client().increment('campus__pyodide_experiment_output_type_mismatch', {}, 1);
};

const countOutputValueMismatch = () => {
  client().increment('campus__pyodide_experiment_output_value_mismatch', {}, 1);
};

// This is a code execution backend combined of the mux + pyodide, but we only show the mux results to the user.
// We send the same commands to the pyodide backend just to measure user impact.
export const dualCodeExecutionBackend = ({
  language,
  state,
  userInfo,
}: {
  language: Language;
  state: State;
  userInfo: unknown;
}): CodeExecutionBackend => {
  const muxSession = new mux.AsyncSession(userInfo, {
    multiplexerUrl: selectors.selectMultiplexerUrl(state),
    language,
    flattenOutputs: false,
    retryOptions: { delay: 1000, nbOfRetry: 10 },
  });
  const pyodideBackend = createPyodideBackend();

  const sessionCreatedAt = Date.now();

  let pyodideReady = false;
  let muxReady = false;
  let ignoredPyodide = false;

  let pyodideInputStartedAt: number | null = null;
  let muxInputStartedAt: number | null = null;

  let pyodideOutputHistory: SessionOutput[] = [];
  let muxOutputHistory: SessionOutput[] = [];

  const compareOutputs = () => {
    if (pyodideOutputHistory.length === 0 || muxOutputHistory.length === 0) {
      return;
    }

    if (pyodideOutputHistory.length !== muxOutputHistory.length) {
      if (
        muxOutputHistory.length === pyodideOutputHistory.length + 1 &&
        muxOutputHistory[0].type === 'output' &&
        muxOutputHistory[0].payload.startsWith(
          'ERROR! Session/line number was not unique in database',
        )
      ) {
        raven.captureMessage('Session error in mux', {
          level: 'warning',
          extra: {
            pyodideOutputHistory,
            muxOutputHistory,
          },
        });
        muxOutputHistory.shift();
      } else {
        raven.captureMessage('Number of outputs mismatch', {
          level: 'warning',
          extra: {
            pyodideOutputHistory,
            muxOutputHistory,
          },
        });
        countNumberOfOutputsMismatch();
        return;
      }
    }

    const outputs = zip(pyodideOutputHistory, muxOutputHistory);

    // eslint-disable-next-line no-restricted-syntax
    for (const [pyodideOutput, muxOutput] of outputs) {
      if (pyodideOutput == null || muxOutput == null) {
        // should be unreachable
        break;
      }

      if (pyodideOutput.type !== muxOutput.type) {
        raven.captureMessage('Output type mismatch', {
          level: 'warning',
          extra: { pyodideOutput, muxOutput },
        });
        countOutputTypeMismatch();
        continue; // eslint-disable-line no-continue
      }

      switch (pyodideOutput.type) {
        case 'error':
          // We don't compare error messages
          break;
        case 'sct':
          if (!pyodideOutput.payload.correct && !muxOutput.payload.correct) {
            // We don't compare SCT errors - if both are incorrect it's ok.
            // This is because error messages are different between pyodide <> mux because of the different python
            // versions.
            // It somewhat decreases the value of the experiment, but it's better than having a lot of false positives.
            // As an alternative, we can check for the content "syntax error" and only ignore those.
            continue; // eslint-disable-line no-continue
          }
          if (!isDeepEqual(pyodideOutput, muxOutput)) {
            raven.captureMessage('Output value mismatch', {
              level: 'warning',
              extra: { pyodideOutput, muxOutput },
            });
            countOutputValueMismatch();
          }
          break;
        default:
          // Ignore some expected differences between the different Python versions, to reduce false positives.
          if (
            hasOutputDifferentFormattingAcrossDifferentVersions(
              pyodideOutput,
              muxOutput,
            )
          ) {
            continue; // eslint-disable-line no-continue
          }

          if (!isDeepEqual(pyodideOutput, muxOutput)) {
            raven.captureMessage('Output value mismatch', {
              level: 'warning',
              extra: { pyodideOutput, muxOutput },
            });
            countOutputValueMismatch();
          }
          break;
      }
    }

    pyodideOutputHistory = [];
    muxOutputHistory = [];
  };

  const addOutputToPyodideHistory = (
    output: SessionOutput | SessionOutput[],
  ) => {
    if (Array.isArray(output)) {
      pyodideOutputHistory = output;
    } else {
      pyodideOutputHistory = [output];
    }

    compareOutputs();
  };

  const addOutputToMuxHistory = (output: SessionOutput | SessionOutput[]) => {
    if (Array.isArray(output)) {
      muxOutputHistory = output;
    } else {
      muxOutputHistory = [output];
    }

    compareOutputs();
  };

  muxSession.output$.subscribe((output) => {
    if (!ignoredPyodide) {
      // Only add to history outputs that will be compared. If pyodide
      // was ignored, this output won't be compared.
      addOutputToMuxHistory(output);
    }

    if (muxInputStartedAt == null) {
      return;
    }

    const timeToExecuteCode = Date.now() - muxInputStartedAt;

    measureTimeToRunOrSubmitCode({
      environment: 'mux',
      time: timeToExecuteCode,
    });

    muxInputStartedAt = null;
  });

  pyodideBackend.output$.subscribe((output) => {
    addOutputToPyodideHistory(output);

    if (pyodideInputStartedAt == null) {
      return;
    }

    const timeToExecuteCode = Date.now() - pyodideInputStartedAt;

    measureTimeToRunOrSubmitCode({
      environment: 'wasm',
      time: timeToExecuteCode,
    });

    pyodideInputStartedAt = null;
  });

  muxSession.subscribe(({ status }) => {
    if (status === 'ready' && !muxReady) {
      muxReady = true;
      const timeUntilReady = Date.now() - sessionCreatedAt;
      measureTimeUntilSessionReady({
        environment: 'mux',
        time: timeUntilReady,
      });
    }
  });

  pyodideBackend.subscribe(({ status }) => {
    if (status === 'ready' && !pyodideReady) {
      pyodideReady = true;
      const timeUntilReady = Date.now() - sessionCreatedAt;
      measureTimeUntilSessionReady({
        environment: 'wasm',
        time: timeUntilReady,
      });
    }
  });

  return {
    client: {
      off(event) {
        muxSession.client.off(event);
      },
      on(event, callback) {
        muxSession.client.on(event, callback);
      },
    },
    input(command) {
      muxInputStartedAt = Date.now();
      muxSession.input(command);

      if (pyodideReady) {
        pyodideInputStartedAt = Date.now();
        pyodideBackend.input(command);
      } else {
        ignoredPyodide = true;
        countPyodideSessionNotReadyWhenRunningCode();
      }
    },
    options: { language },
    // We only show the mux results to the user
    output$: muxSession.output$,
    start(options, initCommand) {
      muxSession.start(options, initCommand);
      pyodideBackend.start(options, initCommand);
    },
    stop() {
      pyodideBackend.stop();
      return muxSession.stop();
    },
    stopPolling() {
      pyodideBackend.stopPolling();
      return muxSession.stopPolling();
    },
    subscribe(subscriber) {
      // We only show mux readiness status to the user
      return muxSession.subscribe(subscriber);
    },
    subscribeToOutputs(predicate, listener) {
      // We only show mux outputs to the user
      return muxSession.subscribeToOutputs(predicate, listener);
    },
  };
};
