// eslint-disable-next-line no-restricted-imports
import isNil from 'lodash/isNil';
import result from 'lodash/result';
// eslint-disable-next-line no-restricted-imports
import Rx from 'rxjs/Rx';

import config from '../../config';
import ApiClient from '../../helpers/ApiClient';
import {
  getCampusApiClient,
  getMainAppApiClient,
  getTeachClient,
} from '../../helpers/getClients';
import { isTeachPreview } from '../../helpers/isTeachPreview';
import * as actions from '../actions';
import * as selectors from '../selectors';

const mainAppClient = getMainAppApiClient();
const campusApiClient = getCampusApiClient();
const teachClient = getTeachClient();

const submitExercise = (action: any, store: any, history: any) => {
  const state = store.getState();
  const id =
    selectors.selectCurrentSubExercise(state).get('id') ||
    selectors.selectExercise(state).get('id');
  const contentLanguage = selectors.selectPathLanguage(store.getState());
  if (!id) {
    return Rx.Observable.empty();
  }
  const exercise = selectors.selectExercise(state).toJS();
  const submittedCode =
    action.code || selectors.selectLastCode(store.getState()) || '';
  const subEx = selectors.selectCurrentSubExercise(store.getState());
  const xp = subEx.isEmpty()
    ? result(exercise, 'user.currentXp')
    : subEx.get('xpToWin');

  const isPreview = isTeachPreview(history.location.pathname);

  return (isPreview ? teachClient : campusApiClient).execute(
    ApiClient.endpoint.postSubmitCode({
      authentication_token: mainAppClient.getAuthenticationToken(),
      code: submittedCode,
      correct: action.correct,
      feedback_msg: action.message,
      id,
      xp: isNil(action.xp) ? xp : action.xp,
      contentLanguage,
    }),
  );
};

const submitToLti = () =>
  mainAppClient
    .execute(ApiClient.endpoint.submitLti())
    .map(() =>
      actions.setLtiStatus({
        status: 'success',
        message: 'Score successfully sent',
      }),
    )
    .catch(() =>
      Rx.Observable.of(
        actions.setLtiStatus({
          status: 'failure',
          message: 'There was an error sending your score',
        }),
      ),
    );

export const requestToLti = (action: any, store: any, isRetry = false) => {
  const course = selectors.selectCourse(store.getState()).toJS();
  const chapter = selectors.selectChapter(store.getState()).toJS();
  const submit$ = submitToLti();
  if (action.correct || isRetry) {
    const { ltiSettings } = mainAppClient;
    // if ltiSettings.chapterId is same as current chapter, and ltiSettings.active is available:
    // don't do is_active call again, but decided based on lti active
    if (ltiSettings != null && ltiSettings.chapterId === chapter.id) {
      return ltiSettings.active ? submit$ : Rx.Observable.empty();
    }
    return mainAppClient
      .execute(
        ApiClient.endpoint.ltiIsActive({
          courseId: course.id,
          chapterId: chapter.id,
        }),
      )
      .concatMap((response: any) => {
        if (response.body) {
          mainAppClient.ltiSettings = {
            active: true,
            chapterId: chapter.id,
          };
          return submit$;
        }
        return Rx.Observable.throw('200-but-empty');
      })
      .catch(() => {
        mainAppClient.ltiSettings = {
          active: false,
          chapterId: chapter.id,
        };
        return Rx.Observable.of(
          actions.setLtiStatus({
            status: 'inactive',
            message: 'No LTI key in session',
          }),
        );
      });
  }
  return Rx.Observable.empty();
};

export const submitToMainApp = (action: any, store: any, history: any) =>
  submitExercise(action, store, history)
    .concatMap((response: any) => {
      let postSubmitActions$ = requestToLti(action, store);
      if (response.body && response.body.xp_gained != null) {
        postSubmitActions$ = postSubmitActions$.merge(
          Rx.Observable.of(
            actions.exerciseCompleted({
              xpGained: response.body.xp_gained,
              currentTime: Date.now(),
            }),
          ),
        );
      }
      return postSubmitActions$;
    })
    .catch((error: any) => {
      const obs$ = Rx.Observable.of();

      const errorStatus = result(error, 'error.status');
      const isUserUnauthorized = errorStatus === 401;
      const isUserForbidden = errorStatus === 403;

      if (isUserUnauthorized) {
        return obs$.concat(
          Rx.Observable.of(
            actions.showModal({ modal: selectors.MODAL_TYPE.LOGIN }),
          ),
        );
      }
      if (isUserForbidden) {
        return obs$.concat(Rx.Observable.of(actions.showPaywallModal()));
      }
      return obs$.concat(
        // Propagate the error to avoid doing other actions.
        // The error should be caught by a catch at the end of the middleware
        Rx.Observable.throw(error),
      );
    });

export const reportOutputToHeap = (action: any, store: any) => {
  const state = store.getState();
  const userId = selectors.selectUserSettings(state).get('id');
  const exerciseId =
    selectors.selectCurrentSubExercise(state).get('id') ||
    selectors.selectExercise(state).get('id');
  const exerciseType =
    selectors.selectCurrentSubExercise(state).get('type') ||
    selectors.selectExercise(state).get('type');
  const { lastSubmittedCode, lastSubmittedCommand } =
    selectors.selectBackendSession(state) ?? {};
  const isPyodideBackendEnabled =
    selectors.selectIsPyodideBackendEnabled(state);
  const heapEventName = isPyodideBackendEnabled
    ? 'Pyodide Output'
    : 'Mux Output';
  const { appName } = config;
  if (window.dataLayer) {
    window.dataLayer.push({
      event: 'heap_event',
      heap_event_name: `Learn - Campus - ${heapEventName}`,
      heap_event_properties: {
        app_id: appName,
        event_category: 'learning',
        identity: userId,
        exercise_id: exerciseId,
        exercise_type: exerciseType,
        code: lastSubmittedCode,
        command: lastSubmittedCommand,
        output_type: action.output.type,
        output_payload: action.output.payload,
      },
    });
  }
  return Rx.Observable.empty();
};

const calls = (store: any, action: any, history: any) => {
  switch (action.type) {
    case actions.BOOT_SUCCEEDED: {
      mainAppClient.setUserSettings(action.entities.user.settings);
      return Rx.Observable.empty();
    }
    case actions.RETRY_REQUEST_TO_LTI:
      return requestToLti(action, store, true);
    case actions.SUBMIT_TO_MAIN_APP:
      return submitToMainApp(action, store, history);
    case actions.REPORT_OUTPUT_TO_HEAP:
      return reportOutputToHeap(action, store);
    case actions.ADVANCE_ONBOARDING_MILESTONES:
    case actions.SKIP_ONBOARDING_MILESTONES:
      return campusApiClient
        .execute(ApiClient.endpoint.seenCampusTour())
        .mergeMapTo(Rx.Observable.empty());
    case actions.SHOW_SOLUTION:
      return (
        isTeachPreview(history.location.pathname)
          ? teachClient
          : campusApiClient
      )
        .execute(
          ApiClient.endpoint.getSolution({ exerciseId: action.exerciseId }),
        )
        .mergeMapTo(Rx.Observable.empty());
    case actions.SHOW_HINT:
      return (
        isTeachPreview(history.location.pathname)
          ? teachClient
          : campusApiClient
      )
        .execute(ApiClient.endpoint.getHint({ exerciseId: action.exerciseId }))
        .mergeMapTo(Rx.Observable.empty());
    default:
      return Rx.Observable.empty();
  }
};

export const epicExternalCalls = (action$: any, store: any, history: any) =>
  action$.concatMap((action: any) =>
    calls(store, action, history).catch(() => Rx.Observable.empty()),
  );
