/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable no-param-reassign */
import { useMediaQuery } from '@datacamp/waffles/hooks';
import filter from 'lodash/filter';
import reduce from 'lodash/reduce';
import React, { Component, useEffect, useState } from 'react';
import { ReactReduxContext } from 'react-redux';

import GoldenComponent from '../HighOrderComponents/GoldenComponent';

import layoutBuilder from './layoutBuilder';

type Props = {
  // import { exerciseTypes } from '../ExerciseHandler/ExerciseHandler';
  exercise?: any;
  // TODO: PropTypes.shape(exerciseTypes)
  mobileView: 'CONTEXT' | 'EXERCISE';
  startSession: (...args: any[]) => any;
};

// @ts-expect-error ts-migrate(7024) FIXME: Function implicitly has return type 'any' because ... Remove this comment to see the full error message
const isContentEmpty = (item: any) => {
  if (item.content) {
    return reduce(
      item.content,
      (acc, contentItem) => acc && isContentEmpty(contentItem),
      true,
    );
  }
  return false;
};

const filterEmptyContent = (item: any) => {
  if (item.content) {
    item.content = filter(
      item.content,
      (contentItem) => !isContentEmpty(filterEmptyContent(contentItem)),
    );
  }
  return item;
};

class BaseLayoutDesktop extends Component<Props> {
  componentDidMount() {
    switch (this.props.exercise.type) {
      case 'TabExercise':
      case 'BulletExercise':
        break;
      default:
        this.props.startSession(false);
        break;
    }
  }

  render() {
    const { exercise } = this.props;
    const builder = layoutBuilder(exercise.type);

    const config = builder({ ...this.props });

    filterEmptyContent(config);

    return (
      // We have to inject the store in order to create a Provider for each Golden layout item
      <ReactReduxContext.Consumer>
        {/* @ts-expect-error ts-migrate(2769) FIXME: Property 'config' does not exist on type 'Intrinsi... Remove this comment to see the full error message */}
        {({ store }: any) => <GoldenComponent config={config} store={store} />}
      </ReactReduxContext.Consumer>
    );
  }
}

class BaseLayoutMobile extends Component<Props> {
  componentDidMount() {
    switch (this.props.exercise.type) {
      case 'TabExercise':
      case 'BulletExercise':
        break;
      default:
        this.props.startSession(false);
        break;
    }
  }

  render() {
    const { exercise } = this.props;
    const builder = layoutBuilder(exercise.type);

    const config = builder({ ...this.props });

    // This is extracting just the context of the exercise
    const contextConfig = {
      ...config,
      content: [
        { ...config.content[0], content: [config.content[0].content[0]] },
      ],
    };

    // This is extracting just the exercise of the exercise
    const exerciseConfig = {
      ...config,
      content: [
        { ...config.content[0], content: [config.content[0].content[1]] },
      ],
    };

    filterEmptyContent(config);

    return (
      // We have to inject the store in order to create a Provider for each Golden layout item
      <ReactReduxContext.Consumer>
        {({ store }: any) => (
          <>
            <div
              style={{
                visibility:
                  this.props.mobileView === 'CONTEXT' ? 'visible' : 'hidden',
              }}
            >
              <GoldenComponent
                // @ts-expect-error ts-migrate(2769) FIXME: Property 'config' does not exist on type 'Intrinsi... Remove this comment to see the full error message
                config={contextConfig}
                store={store}
                key="context"
              />
            </div>
            <div
              style={{
                visibility:
                  this.props.mobileView === 'EXERCISE' ? 'visible' : 'hidden',
              }}
            >
              <GoldenComponent
                // @ts-expect-error ts-migrate(2769) FIXME: Property 'config' does not exist on type 'Intrinsi... Remove this comment to see the full error message
                config={exerciseConfig}
                store={store}
                key="exercise"
              />
            </div>
          </>
        )}
      </ReactReduxContext.Consumer>
    );
  }
}

// We've noticed that useMediaQuery initially returns wrong values.
// This is flagged to the design system squad (slack: https://datacamp.slack.com/archives/CHT1GJV0E/p1734551544132449)
// The hook below is a workaround for this.
const useIsAboveSmall = () => {
  const { isAboveSmall } = useMediaQuery();

  const [isInitialRender, setIsInitialRender] = useState(true);
  const [isAboveSmallPatched, setIsAboveSmallPatched] = useState(
    window.innerWidth > 480,
  );

  useEffect(() => {
    if (isInitialRender) {
      setIsInitialRender(false);
      return;
    }

    setIsAboveSmallPatched(isAboveSmall);
  }, [isAboveSmall, isInitialRender]);

  return { isAboveSmall: isAboveSmallPatched };
};

const BaseLayout: React.FC<Props> = (props) => {
  const { isAboveSmall } = useIsAboveSmall();
  const shouldShowEditorToggle = !isAboveSmall;

  return shouldShowEditorToggle ? (
    <BaseLayoutMobile {...props} />
  ) : (
    <BaseLayoutDesktop {...props} />
  );
};

export default BaseLayout;
