import type { Language as ClientLanguage } from '@datacamp/multiplexer-client';
import type { Language as ClientJsonRpcLanguage } from '@datacamp/multiplexer-client-jsonrpc';
import type { History } from 'history';
import raven from 'raven-js';
import type { Store } from 'redux';
import type { ActionsObservable } from 'redux-observable';

import config from '../../config';
import { isTeachPreview } from '../../helpers/isTeachPreview';
import prometheusClient from '../../helpers/prometheusClient';
import type { State } from '../../interfaces/State';
import type { Action } from '../actions';
import * as actions from '../actions';
import * as selectors from '../selectors';

const MAX_TIMING_THRESHOLD_IN_MS = 180000;

const client = prometheusClient();

type Language = ClientJsonRpcLanguage | ClientLanguage;

// FIXME: TEMPORARY CODE TO SOLVE N/A ERROR MESSAGE
// Prevent using the shortcut to save the page (it crashes the multiplexer connection)
const onKeyDown = (e: any) => {
  if (Number(e.keyCode) === 83 && (e.metaKey || e.ctrlKey)) {
    e.preventDefault();
  }
};

export const epicSessionStarted = (
  action$: ActionsObservable<Action>,
  store: Store<State, Action>,
  history: History,
) => {
  return action$
    .ofType(actions.EPIC_UPDATE_BACKEND_STATUS)
    .scan((previousStatus: string, currentAction) => {
      const isPreview = isTeachPreview(history.location.pathname);
      const currentStatus = currentAction.status;
      const isSessionReady =
        previousStatus === selectors.BACKEND_STATUS.NONE.code &&
        currentStatus === selectors.BACKEND_STATUS.READY.code;
      const state = store.getState();
      const isPyodideBackendEnabled =
        selectors.selectIsPyodideBackendEnabled(state);

      if (isSessionReady && !isPreview) {
        client.increment(
          'le_count_exercise_session_ready',
          {
            isPyodideBackendEnabled,
          },
          1,
        );
      }
      return currentStatus;
    }, 'none')
    .concatMapTo([]);
};

export const epicOnCrashedSession = (
  action$: ActionsObservable<Action>,
  store: Store<State, Action>,
  history: History,
) => {
  document.removeEventListener('keydown', onKeyDown);
  document.addEventListener('keydown', onKeyDown, false);

  return action$
    .ofType(actions.EPIC_UPDATE_BACKEND_STATUS)
    .filter((action) => selectors.BACKEND_STATUS.BROKEN.code === action.status)
    .do((action) => {
      const state = store.getState();
      const { appName } = config;
      const multiplexerUrl = selectors.selectMultiplexerUrl(state);
      const reason = action.message;
      const course = selectors.selectCourse(state);
      const chapter = selectors.selectChapter(state);
      const exercise = selectors.selectExercise(state);
      const isPreview = isTeachPreview(history.location.pathname);
      const isPyodideBackendEnabled =
        selectors.selectIsPyodideBackendEnabled(state);
      const titlePrefix = isPyodideBackendEnabled ? 'Pyodide' : 'Multiplexer';

      if (!action.message) {
        raven.captureException(action.error, {
          level: 'info',
          extra: {
            url: window.location.href,
          },
        });
      }
      if (window.heap != null) {
        window.heap.track(`${titlePrefix} Session Crash`, {
          appName: config.appName,
          chapterId: chapter.get('id'),
          courseId: course.get('id'),
          courseState: course.get('state'),
          exerciseId: exercise.get('id'),
          exerciseType: exercise.get('type'),
          exerciseVersion: exercise.get('version') || 'v0',
          isTeachPreview: isPreview ? 'true' : 'false',
          language: selectors.selectLanguage(state),
          multiplexerUrl,
          reason,
          runtimeConfig: selectors.selectRuntimeConfig(state),
        });
      }

      if (isPreview) {
        return;
      }

      client.increment(
        'le_count_crashed_sessions',
        {
          appName,
          message: reason,
          courseId: course.get('id'),
          isPyodideBackendEnabled,
        },
        1,
      );
    })
    .concatMapTo([]);
};

function shouldLogTechnologyId(technologyId: number | undefined): boolean {
  if (technologyId == null) {
    return false;
  }
  return [
    17, // Snowflake
  ].includes(technologyId);
}

function shouldLogRuntimeConfig(
  runtimeConfig: string | undefined,
): runtimeConfig is string {
  if (runtimeConfig == null) {
    return false;
  }
  return runtimeConfig.includes('gpu');
}

function getWorkspaceReadyDistributionLabels(
  language: Language,
  store: Store<State, Action>,
): Record<string, boolean | number | string> {
  const state = store.getState();
  const isPyodideBackendEnabled =
    selectors.selectIsPyodideBackendEnabled(state);
  const labels: Record<string, boolean | number | string> = {
    appName: config.appName,
    language,
    isPyodideBackendEnabled,
  };

  const runtimeConfig = selectors.selectRuntimeConfig(store.getState());
  if (shouldLogRuntimeConfig(runtimeConfig)) {
    labels.runtimeConfig = runtimeConfig;
  }

  const technologyId = selectors.selectTechnologyId(store.getState());
  if (shouldLogTechnologyId(technologyId)) {
    labels.technologyId = technologyId;
  }

  return labels;
}

export const epicOnWorkspaceReady = (
  action$: ActionsObservable<Action>,
  store: Store<State, Action>,
) =>
  action$
    .ofType(actions.EPIC_START_SESSION)
    .switchMap(({ language, timestamp: timeStarted }) =>
      action$
        .ofType(actions.EPIC_UPDATE_BACKEND_STATUS)
        .filter(
          (action) => selectors.BACKEND_STATUS.READY.code === action.status,
        )
        .map(({ timestamp: timeSessionReady }) => ({
          time: (timeSessionReady ?? new Date().getTime()) - timeStarted,
          language,
        }))
        .take(1),
    )
    .do(({ language, time }) => {
      const labels = getWorkspaceReadyDistributionLabels(language, store);
      client.observeDistribution('le_time_workspace_to_be_ready', labels, time);
    })
    .concatMapTo([]);

export const epicOnCodeSubmitted = (
  action$: ActionsObservable<Action>,
  store: Store<State, Action>,
  history: History,
) =>
  action$
    .ofType(actions.ON_CODE_SUBMITTED)
    .exhaustMap(({ language, timestamp: timeCodeSubmitted }: any) =>
      action$
        .ofType(actions.RESULT_EXERCISE)
        .timestamp()
        .map(({ timestamp: timeResult }) => ({
          time: timeResult - timeCodeSubmitted,
          language,
        }))
        .take(1)
        .takeUntil(action$.ofType(actions.EPIC_START_SESSION)),
    )
    .do(({ language, time }) => {
      const isPreview = isTeachPreview(history.location.pathname);
      if (isPreview) {
        return;
      }
      const state = store.getState();
      const isPyodideBackendEnabled =
        selectors.selectIsPyodideBackendEnabled(state);
      const courseId = selectors.selectCourse(state).get('id');

      client.observeDistribution(
        'le_time_to_submit_code',
        {
          appName: config.appName,
          language,
          isPyodideBackendEnabled,
        },
        time,
      );

      const shouldFilterAnomalousTimingData =
        time >= MAX_TIMING_THRESHOLD_IN_MS;
      if (shouldFilterAnomalousTimingData) {
        return;
      }

      client.observeDistribution(
        'campus__time_to_submit_code',
        {
          language,
          courseId: language === 'python' ? courseId : undefined,
        },
        time,
      );
    })
    .concatMapTo([]);

export const epicOnReportSent = (
  action$: ActionsObservable<Action>,
  _store: Store<State, Action>,
  history: History,
) =>
  action$
    .ofType(actions.EPIC_SUBMIT_ISSUE)
    .do(() => {
      client.increment(
        'le_count_report_sent',
        {
          appName: config.appName,
          isTeachPreview: isTeachPreview(history.location.pathname),
        },
        1,
      );
    })
    .concatMapTo([]);
