import { useCallback, useEffect, useMemo, useState } from 'react';

import config from '../../config';
import { mobileApi } from '../../helpers/api/clients';
import { isFeatureEnabled } from '../../helpers/featureFlags';
import { STORAGE_KEYS, useStorage } from '../../helpers/useStorage';
import { useSelector } from '../../interfaces/State';
import {
  selectCourse,
  selectStreakDays,
  selectStreakFreezesAwardedInSession,
  selectUserSettings,
} from '../../redux/selectors';

function useFirstMobileSignIn({ skip = false }): {
  firstMobileSignInAt?: string | null;
  isLoading: boolean;
} {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [hasRunBefore, setHasRunBefore] = useState<boolean>(false);
  const [firstMobileSignInAt, setFirstMobileSignInAt] = useState<string | null>(
    null,
  );

  useEffect(() => {
    if (skip || hasRunBefore) {
      return;
    }

    setLoading(true);
    setFirstMobileSignInAt(null);
    mobileApi
      .getFirstMobileSignInAt()
      .then((res) =>
        setFirstMobileSignInAt(res?.body?.data?.user?.firstMobileSignInAt),
      )
      .catch(() => setFirstMobileSignInAt(null))
      .finally(() => {
        setHasRunBefore(true);
        setLoading(false);
      });
  }, [firstMobileSignInAt, hasRunBefore, skip]);

  return {
    firstMobileSignInAt,
    isLoading,
  };
}

type ActiveScreen =
  | 'DAILY_STREAK'
  | 'FREEZES_AWARDED'
  | 'MOBILE_APP'
  | 'PICK_A_GOAL';

const DAYS_IN_MS = 86_400_000; // 24 * 60 * 60 * 1000; 1 day
const PICK_STREAK_GOAL_INTERVAL = 30 * DAYS_IN_MS;
const APP_SCREEN_INTERVAL = 90 * DAYS_IN_MS; // 90 days

function useDailyStreakNavigation({
  onExit,
  show,
}: {
  onExit: () => void;
  show?: boolean;
}): {
  onNextScreen: () => void;
  screen?: ActiveScreen;
} {
  const streakDays = useSelector(selectStreakDays);
  const freezesAwardedInSession = useSelector(
    selectStreakFreezesAwardedInSession,
  );
  const campusCourseSupported =
    useSelector(selectCourse).get('mobile_enabled') ?? false;
  const userId = useSelector(selectUserSettings).get('id');

  const { setValue: setPickStreakGoalSeen, value: lastSeenPickStreakGoal } =
    useStorage(STORAGE_KEYS.PICK_STREAK_GOAL_SCREEN_SEEN, 0);

  const [screen, setScreen] = useState<ActiveScreen>('DAILY_STREAK');

  const onFinish = useCallback(() => {
    onExit();
    setScreen('DAILY_STREAK');
  }, [onExit]);

  // Mobile App Screen
  const { firstMobileSignInAt } = useFirstMobileSignIn({ skip: !show });
  const { setValue: setLastSeenAppScreen, value: lastSeenAppScreen } =
    useStorage(STORAGE_KEYS.MOBILE_APP_SCREEN_SEEN, 0);

  const shouldShowMobileScreen = useMemo(() => {
    const isSecondDay = streakDays === 2;
    const isCourseSupportedInMobile = campusCourseSupported;
    const hasUsedMobile = !!firstMobileSignInAt;

    if (!isSecondDay || !isCourseSupportedInMobile || hasUsedMobile) {
      return false;
    }

    const shouldShow = Date.now() - lastSeenAppScreen > APP_SCREEN_INTERVAL;

    return shouldShow;
  }, [
    firstMobileSignInAt,
    campusCourseSupported,
    streakDays,
    lastSeenAppScreen,
  ]);

  const shouldShowPickStreakGoalScreen = useMemo(() => {
    return Date.now() - lastSeenPickStreakGoal > PICK_STREAK_GOAL_INTERVAL;
  }, [lastSeenPickStreakGoal]);

  const hasMobileScreenEnabled = useMemo(() => {
    if (!shouldShowMobileScreen) {
      return false;
    }

    return isFeatureEnabled('wl--q4-2024-campus-engagement-test1');
  }, [shouldShowMobileScreen]);

  useEffect(() => {
    if (
      !hasMobileScreenEnabled ||
      screen !== 'MOBILE_APP' ||
      !shouldShowMobileScreen
    ) {
      return;
    }

    if (window.dataLayer && userId) {
      window.dataLayer.push({
        event: 'heap_event',
        heap_event_name: 'Learn - Campus - User Sees Streak Mobile App Modal',
        heap_event_properties: {
          app_id: config.appName,
          event_category: 'learning',
          identity: userId,
        },
      });
    }

    setLastSeenAppScreen(Date.now());
  }, [
    shouldShowMobileScreen,
    screen,
    hasMobileScreenEnabled,
    setLastSeenAppScreen,
    userId,
  ]);

  // Freezes
  const onFreezesAwardedExit = useCallback(() => {
    if (hasMobileScreenEnabled && shouldShowMobileScreen) {
      setScreen('MOBILE_APP');
      return;
    }

    onFinish();
  }, [onFinish, hasMobileScreenEnabled, shouldShowMobileScreen]);

  // Pick a goal screen Exit
  const onPickStreakGoalExit = useCallback(() => {
    if (freezesAwardedInSession) {
      setScreen('FREEZES_AWARDED');
      return;
    }

    if (hasMobileScreenEnabled && shouldShowMobileScreen) {
      setScreen('MOBILE_APP');
      return;
    }

    onFinish();
  }, [
    freezesAwardedInSession,
    hasMobileScreenEnabled,
    onFinish,
    shouldShowMobileScreen,
  ]);

  // Initial screen
  const onDailyStreakExit = useCallback(() => {
    if (
      shouldShowPickStreakGoalScreen &&
      isFeatureEnabled('wl--exp-2025-01-campus-pick-streak-goal')
    ) {
      setScreen('PICK_A_GOAL');
      setPickStreakGoalSeen(Date.now());
    } else {
      onPickStreakGoalExit();
    }
  }, [
    shouldShowPickStreakGoalScreen,
    setPickStreakGoalSeen,
    onPickStreakGoalExit,
  ]);

  // Screen navigation
  return {
    screen,
    onNextScreen: useCallback(() => {
      if (screen === 'DAILY_STREAK') {
        return onDailyStreakExit();
      }

      if (screen === 'PICK_A_GOAL') {
        return onPickStreakGoalExit();
      }

      if (screen === 'FREEZES_AWARDED') {
        return onFreezesAwardedExit();
      }

      if (screen === 'MOBILE_APP') {
        return onFinish();
      }

      return onFinish();
    }, [
      screen,
      onFinish,
      onDailyStreakExit,
      onPickStreakGoalExit,
      onFreezesAwardedExit,
    ]),
  };
}

export default useDailyStreakNavigation;
