import cn from 'classnames';
import React, { useMemo } from 'react';

import hcCollapsePanel from '../HighOrderComponents/hcCollapsePanel';

import ConsoleLoader from './ConsoleLoader';
import { OutputConsole } from './OutputConsole';
import XtermWrapper from './XtermWrapper';

const CONSOLE_SESSION_BROKEN = 'console-session-broken';
const CONSOLE_SESSION_ALIVE = 'console-session-alive';

type OwnProps = {
  glContainer?: {};
  isClosed?: boolean;
  isSessionBroken?: boolean;
  language?: string;
  setClosed?: (...args: any[]) => any;
};

const defaultProps = {
  isClosed: false,
};

type Props = OwnProps & typeof defaultProps;

const Console: React.FC<Props> = (props) => {
  const renderConsole = useMemo((): JSX.Element => {
    switch (props.language) {
      case 'java':
      case 'rust':
        // @ts-expect-error missing properties in Props
        return <OutputConsole {...props} />;
      case 'shell':
      case 'containers':
        // @ts-expect-error missing properties in Props
        return <XtermWrapper {...props} />;
      default:
        // @ts-expect-error missing properties in Props
        return <ConsoleLoader {...props} />;
    }
  }, [props]);

  return (
    <div
      className={cn('console--wrapper', { closed: props.isClosed })}
      data-cy={
        props.isSessionBroken ? CONSOLE_SESSION_BROKEN : CONSOLE_SESSION_ALIVE
      }
      translate="no"
    >
      {renderConsole}
    </div>
  );
};
Console.defaultProps = defaultProps;

export default hcCollapsePanel({ fromTopToBottom: true, closedBarHeight: 33 })(
  Console,
);
