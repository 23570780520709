/** @jsx jsx */
import { useMediaQuery } from '@datacamp/waffles/hooks';
import { Text } from '@datacamp/waffles/text';
import { tokens } from '@datacamp/waffles/tokens';
import { jsx } from '@emotion/react';
import React from 'react';
import { Trans } from 'react-i18next';

import { I18N_PLACEHOLDER } from '../../../i18n';

import type { GoalOptionType } from './PickStreakGoalScreen';

export const FeedbackMessage: React.FC<{ goal: GoalOptionType | null }> = ({
  goal,
}) => {
  const { isAboveSmall } = useMediaQuery();
  const divRef = React.useRef<HTMLDivElement>(null);

  React.useLayoutEffect(() => {
    if (divRef.current) {
      let rafId: number;
      divRef.current.style.height = '0';
      divRef.current.style.opacity = '0';

      const timeoutId = setTimeout(() => {
        rafId = requestAnimationFrame(() => {
          if (divRef.current) {
            divRef.current.style.height = '62px';
            divRef.current.style.opacity = '1';
          }
        });
      }, 300);

      return () => {
        clearTimeout(timeoutId);
        cancelAnimationFrame(rafId);
      };
    }

    return () => {};
  }, [goal]);

  return (
    <div
      css={{
        marginTop: tokens.spacing.medium,
        height: 62,
      }}
    >
      {goal && (
        <div
          ref={divRef}
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: tokens.spacing.medium,
            width: isAboveSmall ? 400 : 300,
            backgroundColor: tokens.colors.navyMedium,
            borderRadius: tokens.borderRadius.medium,
            transition: 'height 0.3s, opacity 0.3s',
          }}
        >
          <Trans
            i18nKey="DailyStreakModal.pickGoal.feedbackMessage"
            components={{
              text: (
                <Text
                  as="p"
                  css={{
                    color: tokens.colors.white,
                    '&>span': {
                      color: tokens.colors.green,
                    },
                  }}
                >
                  {I18N_PLACEHOLDER}
                </Text>
              ),
              times: <span />,
            }}
            values={{
              times: goal.feedbackTimes,
              emoji: goal.feedbackEmoji,
            }}
          />
        </div>
      )}
    </div>
  );
};
