import sha256 from 'hash.js/lib/hash/sha/256';
import type React from 'react';
import { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';

import config from '../config';
import { trimAndHashString } from '../helpers/trimAndHashString';
import type { TrackingGeneratorUser } from '../redux/selectors';
import { selectTrackingGeneratorUser } from '../redux/selectors';
import type { DataLayerVariables } from '../window';

const trackingGeneratorScriptUrl = `${config.urls.trackingGenerator}/base.js`;

export const createSha256Hash = (data: string): string =>
  sha256().update(trimAndHashString(data)).digest('hex');

export const getLanguageFromTrackingGeneratorUser = (
  trackingGeneratorUser: TrackingGeneratorUser,
): string => {
  // @TODO: once this repo has been updated to use four character language
  // codes in phrase, we can use DEFAULT_LANGUAGE from i18n.ts
  const { language } = trackingGeneratorUser;
  return language ?? 'en-US';
};

const getUserDataLayerVariables = (
  variables: TrackingGeneratorUser,
): DataLayerVariables => {
  const { activeProducts, email, id, inEnterpriseGroup, loggedIn, phone } =
    variables;

  const userLanguage = getLanguageFromTrackingGeneratorUser(variables);

  return loggedIn
    ? {
        ...(activeProducts && {
          active_products: activeProducts.join(),
        }),
        ...(inEnterpriseGroup && {
          b2b_user: 'true',
        }),
        ...(email && {
          user_email_hashed: createSha256Hash(email),
        }),
        ...(id && {
          user_id: id,
        }),
        ...(userLanguage && {
          user_language: userLanguage,
        }),
        ...(phone && {
          user_phone_number_hashed: createSha256Hash(phone),
        }),
        ...{
          is_test_user:
            document.cookie
              .split(';')
              .map((kv) => kv.trim())
              .indexOf('dc_is_test_user=1') > -1,
        },
      }
    : {};
};

const pushToDataLayer = (dataLayerVariables: DataLayerVariables): void => {
  if (Object.keys(dataLayerVariables).length > 0) {
    if (typeof window.dataLayer === 'undefined') {
      window.dataLayer = [dataLayerVariables];
    } else {
      window.dataLayer.push(dataLayerVariables);
    }
  }
};

const appendTrackingGeneratorScriptToBody = (
  userDataLayerVariables: DataLayerVariables,
): void => {
  const { user_language } = userDataLayerVariables;

  const scriptId = 'dcTrackingGenerator';
  const scriptExists = document.getElementById(scriptId);

  if (!scriptExists) {
    const script = document.createElement('script');

    script.async = true;
    script.id = scriptId;
    script.src = trackingGeneratorScriptUrl;
    script.setAttribute('data-testid', 'tracking-generator-script');

    if (user_language) {
      script.setAttribute('data-dc-lang', user_language);
    }

    document.body.appendChild(script);
  }
};

const TrackingGenerator: React.FC<TrackingGeneratorUser> = ({
  activeProducts,
  email,
  id,
  inEnterpriseGroup,
  language,
  loggedIn,
  notLoggedIn,
  phone,
  statusError,
}) => {
  useEffect(() => {
    // init data layer
    pushToDataLayer({ gtm_version: 2 });
  }, []);

  const enableTrackingGenerator = useMemo(() => {
    return !!(loggedIn || notLoggedIn || statusError);
  }, [loggedIn, notLoggedIn, statusError]);

  useEffect(() => {
    if (enableTrackingGenerator) {
      const userDataLayerVariables = getUserDataLayerVariables({
        activeProducts,
        email,
        id,
        inEnterpriseGroup,
        language,
        loggedIn,
        phone,
      });
      pushToDataLayer(userDataLayerVariables);
      // imperative injection to prevent script rendering within body elements
      appendTrackingGeneratorScriptToBody(userDataLayerVariables);
    }
  }, [
    enableTrackingGenerator,
    activeProducts,
    email,
    id,
    inEnterpriseGroup,
    language,
    loggedIn,
    phone,
  ]);

  return null;
};

const mapStateToProps = (state: any): TrackingGeneratorUser =>
  selectTrackingGeneratorUser(state);

export default connect(mapStateToProps)(TrackingGenerator);
