import type {
  Command,
  Language,
  SessionStatus,
} from '@datacamp/multiplexer-client';
import mux from '@datacamp/multiplexer-client';
import cookies from 'browser-cookies';
import type { History } from 'history';
import attempt from 'lodash/attempt';
import find from 'lodash/find';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import type { Store } from 'redux';
import type { ActionsObservable } from 'redux-observable';
// eslint-disable-next-line no-restricted-imports
import Rx from 'rxjs/Rx';
// @ts-expect-error ts-migrate(7016) FIXME: Try `npm install @types/universal-rx-request` if i... Remove this comment to see the full error message
// eslint-disable-next-line no-restricted-imports
import rxRequest from 'universal-rx-request';

import {
  getCodeExecutionBackend,
  setCodeExecutionBackend,
} from '../../helpers/codeExecutionBackend';
import { dualCodeExecutionBackend } from '../../helpers/dualCodeExecutionBackend';
import { getServiceFromState } from '../../helpers/exercises';
import { isTeachPreview } from '../../helpers/isTeachPreview';
import outputActions from '../../helpers/outputs';
import { createPyodideBackend } from '../../helpers/pyodideBackend';
import type { State } from '../../interfaces/State';
import type { Action } from '../actions';
import * as actions from '../actions';
import type { DatawarehouseSessionInfo } from '../reducers/datawarehouseSession';
import * as selectors from '../selectors';
import { BACKEND_STATUS } from '../selectors';

import { getImageAndRuntimeConfig } from './getImageAndRuntimeConfig';

let countPostNewSession = 0;
let blockMuxRequest = false;

const guardOnBackendError = (
  data: any,
  getLanguage: () => Language,
): Rx.Observable<unknown> => {
  const language = getLanguage();
  const backendErrorType = (() => {
    switch (language) {
      case 'revo':
      case 'r':
        return 'error';
      default:
        return 'backend-error';
    }
  })();
  const backendError = find(data, (output) => output.type === backendErrorType);
  if (backendError) {
    return Rx.Observable.of(actions.epicBackendError(backendError));
  }
  return Rx.Observable.empty();
};

export const handleInputResponse = (
  _action$: any,
  data: any,
  getLanguage: () => Language,
): Rx.Observable<unknown> => {
  let obs = Rx.Observable.of();
  if (!isEmpty(data)) {
    const error$ = guardOnBackendError(data, getLanguage);
    obs = obs.concat(
      Rx.Observable.of(actions.resultExercise({ results: data })),
      error$,
    );
  }
  return obs;
};

// could be refactor in the future
export const epicClearBackendClientSettings = (action$: any) =>
  action$
    .ofType(actions.EPIC_CLEAR_BACKEND_CLIENT_SETTINGS)
    .do(() => {
      blockMuxRequest = true;
    })
    .concatMap(() => Rx.Observable.of());

const createCodeExecutionBackend = ({
  language,
  state,
  userInfo,
}: {
  language: Language;
  state: State;
  userInfo: any;
}) => {
  if (selectors.selectIsPyodideBackendEnabled(state)) {
    return createPyodideBackend();
  }

  if (selectors.selectIsDualCodeExecutionBackendEnabled(state)) {
    return dualCodeExecutionBackend({ language, state, userInfo });
  }

  return new mux.AsyncSession(userInfo, {
    multiplexerUrl: selectors.selectMultiplexerUrl(state),
    language,
    flattenOutputs: false,
    retryOptions: { delay: 1000, nbOfRetry: 10 },
  });
};

const registerMux = (language: Language, state: State, userInfo: any) => {
  const backend = createCodeExecutionBackend({ language, state, userInfo });
  setCodeExecutionBackend(backend);
};

export const epicRegisterMux = (action$: any, store: Store<State, Action>) => {
  const muxStateSubject$ = new Rx.BehaviorSubject<SessionStatus>({
    status: mux.AsyncSession.STATUS.NONE,
  });
  return action$.ofType(actions.BOOT_SUCCEEDED).switchMap((action: any) => {
    const currentState = store.getState();
    const language = selectors.selectLanguage(currentState);
    const userInfo = pick(action.entities.user.settings, [
      'email',
      'authentication_token',
    ]);

    // Don't initiate a mux session when the user is forbidden to execute code (e.g. not registered).
    // We fake the session to be ready so the user can try to submit code and get a login modal.
    if (selectors.selectIsUserNotLoggedIn(currentState)) {
      return [
        actions.epicUpdateBackendStatus({
          status: BACKEND_STATUS.READY.code,
        }),
      ];
    }

    if (
      includes(
        ['r', 'revo', 'python', 'sql', 'shell', 'scala', 'julia', 'containers'],
        language,
      )
    ) {
      registerMux(language, currentState, userInfo);
    } else {
      return [];
    }

    // Registered previously, so we know it's defined
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const muxClient = getCodeExecutionBackend()!;

    muxClient.subscribe((newMuxState) => muxStateSubject$.next(newMuxState));

    const getLanguage = (): Language =>
      selectors.selectLanguage(store.getState());

    return Rx.Observable.merge(
      Rx.Observable.bindCallback<
        'new',
        { body: { proxyId: string | undefined } }
      >((...args) => muxClient.client.on(...args))('new')
        .filter((res) => res.body.proxyId != null)
        .map((res) => actions.addProxy({ proxyId: res.body.proxyId })),
      Rx.Observable.of(actions.epicMuxRegistered()),
      muxClient.output$
        .concatMap((data: any) =>
          handleInputResponse(action$, data, getLanguage),
        )
        .catch(() => Rx.Observable.empty()),
      muxStateSubject$.distinctUntilChanged(isEqual).map((state) =>
        actions.epicUpdateBackendStatus({
          status: state.status,
          statusCode: state.statusCode,
          message: state.message,
          error: state.error,
        }),
      ),
    );
  });
};

// Empty stream if the backend is good.
// Otherwise fetches information from the Status Page
const noConnectionResponse = Rx.Observable.of({
  indicator: 'none',
  description: 'The Status Page could not be reached.',
});

// The request to fetch the backend status.
const backendStatusRequest = {
  method: 'get',
  url: 'https://swwcn587820m.statuspage.io/api/v2/status.json',
  options: {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    json: true,
  },
};

/**
 * Fetch the backend status and create actions depending on status.
 */
export const epicUpdateBackendStatus = (action$: any) =>
  action$.ofType(actions.EPIC_UPDATE_BACKEND_STATUS).mergeMap((action: any) => {
    const thereIsAnIssue =
      action.status === selectors.BACKEND_STATUS.BROKEN.code;
    let systemStatusStream;
    let openCollapsedConsole;

    if (thereIsAnIssue) {
      systemStatusStream = rxRequest(backendStatusRequest)
        .map((response: any) => response.body.status)
        .catch(() => noConnectionResponse);
      openCollapsedConsole = Rx.Observable.of(
        actions.setBottomPanelClosedState(false),
      );
    } else {
      systemStatusStream = Rx.Observable.empty();
      openCollapsedConsole = Rx.Observable.empty();
    }

    return Rx.Observable.merge(
      Rx.Observable.of(
        actions.updateBackendStatus({
          status: action.status,
          statusCode: action.statusCode,
          message: action.message,
        }),
      ),
      openCollapsedConsole,
      systemStatusStream.map((status: any) =>
        actions.updateSystemStatus(status),
      ),
    );
  });

export const epicStopBackendSession = (action$: any) => {
  const obs$ = action$.filter(getCodeExecutionBackend);
  return Rx.Observable.merge(
    obs$
      .ofType(actions.NO_SESSION)
      .do(attempt(() => getCodeExecutionBackend()?.stopPolling())),
    obs$
      .ofType(actions.STOP_BACKEND_SESSION)
      .do(attempt(() => getCodeExecutionBackend()?.stop())),
  ).concatMapTo(Rx.Observable.empty());
};

export const epicCreateSession = (
  action$: ActionsObservable<Action>,
  store: Store<State, Action>,
  history: History,
) =>
  action$
    .ofType(actions.SET_DATAWAREHOUSE_SESSION)
    .combineLatest(
      action$.ofType(actions.EPIC_MUX_REGISTERED),
      (startAction) => startAction,
    )
    .filter(() => getCodeExecutionBackend() != null)
    .withLatestFrom(
      action$.ofType(actions.EPIC_START_SESSION),
      (_ignored, startSessionAction) => startSessionAction,
    )
    .do((startSessionAction) => {
      if (startSessionAction.force_new) {
        cookies.erase('mux-session-id');
        cookies.erase('AWSELB');
      }
    })
    .switchMap((startSessionAction) => {
      const state = store.getState();
      const query = selectors.selectQuery(state).toJS();
      const course = selectors.selectCourse(state).toJS();
      const exercise = selectors.selectExercise(state).toJS();
      const images = selectors.selectImages(state);
      const sessionId = selectors.sessionId(state);
      const { image, runtimeConfig } = getImageAndRuntimeConfig({
        course,
        exercise,
        images,
      });
      countPostNewSession = countPostNewSession + 1 || 1;
      const forceNew =
        startSessionAction.force_new || countPostNewSession === 1;
      // ...query can override the 'session configuration' as dictated by main app or IMB
      let options = {
        ...startSessionAction,
        runtime_config: runtimeConfig,
        course_id: course.id,
        image,
        shared_image: course.shared_image,
        specific_session_id_or_new: sessionId,
        ...query,
        force_new: forceNew,
      };
      options = pick(options, [
        'language',
        'runtime_config',
        'force_new',
        'course_id',
        'image',
        'shared_image',
        'specific_session_id_or_new',
      ]);
      const datawarehouseSession = selectors.selectDatawarehouseSession(state);
      const command: Partial<Command> = {
        command: 'init',
        clientEnvironment: isTeachPreview(history.location.pathname)
          ? 'campus_preview'
          : 'campus',
        ...startSessionAction.initCommandOptions,
      };
      if (datawarehouseSession.status === 'success') {
        command.pec = insertDatawarehouseCredentialsInPec(
          command.pec,
          datawarehouseSession.session,
        );
      }

      getCodeExecutionBackend()?.start(options, command as Command);

      return new Promise((resolve) => {
        getCodeExecutionBackend()?.client.on('new', (res: any) => {
          resolve(actions.setSessionId({ sessionId: res.body.id }));
          getCodeExecutionBackend()?.client.off('new');
        });
      });
    });

function insertDatawarehouseCredentialsInPec(
  pec: string | undefined,
  sessionInfo: DatawarehouseSessionInfo,
): string | undefined {
  if (!pec) {
    return pec;
  }
  return pec
    .replace(/<SESSION_DB_USER>/g, sessionInfo.dbUser)
    .replace(/<SESSION_DB_PASSWORD>/g, sessionInfo.dbPassword)
    .replace(/<SESSION_DB_NAME>/g, sessionInfo.dbName)
    .replace(/<SESSION_DB_ROLE>/g, sessionInfo.dbRole);
}

export const epicSubmitCode = (action$: any, store: Store<State, Action>) =>
  action$
    .ofType(actions.EPIC_SUBMIT_CODE)
    // TODO: HANDLE_RESIZE
    // Remove next filter when all languages handle resize and expand command
    .filter(
      ({ settings: { command, language } }: any) =>
        !includes(['resize', 'expand'], command) ||
        includes(['r', 'revo'], language),
    )
    .map((action: any) => {
      const state = store.getState();
      const commandConfig = getServiceFromState(state).prepareSubmit(
        state,
        action,
      );
      return {
        type: action.type,
        timestamp: action.timestamp,
        ...commandConfig,
      };
    })
    .filter(() => !blockMuxRequest)
    .throttleTime(500)
    .concatMap((action: any) => {
      if (action.useOutputToActions) {
        return outputActions(
          action.language,
          action.output,
          action.exerciseType,
        );
      }
      const muxClient = getCodeExecutionBackend();
      if (muxClient) {
        const backendStatus = selectors.selectBackendSession(
          store.getState(),
        )?.status;
        if (backendStatus?.code !== selectors.BACKEND_STATUS.BROKEN.code) {
          muxClient.input(action);

          // Send side-effect for fitness function tracker.
          // The action is triggered only when code is submitted to the mux.
          return [
            actions.onCodeSubmitted({
              timestamp: action.timestamp,
              language: action.language,
            }),
          ];
        }
      }
      return Rx.Observable.empty();
    });
