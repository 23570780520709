import type { Technology, TechnologyId } from './technology';
import { technologyById } from './technology';

const question = ({ key, subQuestions, ...restQuestion }: any) => ({
  ...restQuestion,
  key,
  subQuestions: subQuestions.map(({ subKey, ...subQuestion }: any) => ({
    ...subQuestion,
    key: `${key}__${subKey}`,
  })),
});
const survey = ({ questions }: any) => ({
  questions: questions.map(question),
});

const vmSurvey = (technology: any) =>
  survey({
    questions: [
      {
        text: 'Issues with the interface',
        key: 'interface',
        subQuestions: [
          {
            text: `${technology} is too laggy`,
            subKey: 'laggy',
          },
          {
            text: `The text and buttons in ${technology} are difficult to read or click`,
            subKey: 'resolution',
          },
          {
            text: 'The course interface is hard to navigate',
            subKey: 'navigate',
          },
          {
            text: "It's cumbersome to load workbooks",
            subKey: 'workbooks',
          },
          {
            text: 'Something is broken',
            subKey: 'broken',
          },
        ],
      },
      {
        text: 'Issues with the course material',
        key: 'course_material',
        subQuestions: [
          {
            text: 'The exercise instructions are not clear',
            subKey: 'instructions',
          },
          {
            text: "The videos didn't adequately prepare me for the exercises",
            subKey: 'videos',
          },
        ],
      },
      {
        text: 'The course is not engaging or exciting',
        key: 'engagement',
        subQuestions: [
          {
            text: "It's too difficult or fast",
            subKey: 'too_hard',
          },
          {
            text: "It's too easy or slow",
            subKey: 'too_easy',
          },
          {
            text: 'The exercises are not relevant',
            subKey: 'not_relevant',
          },
          {
            text: 'I find the course boring',
            subKey: 'boring',
          },
        ],
      },
      {
        text: "Not the material I'm looking for",
        key: 'wrong_material',
        subQuestions: [],
      },
      {
        text: "I'm done learning for now, I will come back later",
        key: 'done_for_now',
        subQuestions: [],
      },
      {
        text: 'Something else',
        key: 'something_else',
        subQuestions: [],
      },
    ],
  });

const surveys: Record<Technology, ReturnType<typeof vmSurvey> | null> = {
  R: null,
  Python: null,
  SQL: null,
  Git: null,
  Shell: null,
  'Google Sheets': null,
  Theory: null,
  Scala: null,
  Tableau: vmSurvey('Tableau'),
  Excel: null,
  'Power BI': vmSurvey('Power BI'),
  Julia: null,
  Docker: null,
  Snowflake: null,
  Redshift: null,
  BigQuery: null,
  ChatGPT: null,
  OpenAI: null,
  PyTorch: null,
  Databricks: null,
  Airflow: null,
  Spark: null,
  dbt: null,
  AWS: null,
  MLflow: null,
  Azure: null,
  GCP: null,
  Kubernetes: null,
  DVC: null,
  Kafka: null,
  Alteryx: null,
  Java: null,
  Rust: null,
  Llama: null,
  KNIME: null,
  FastAPI: null,
  'Microsoft Copilot': null,
  Github: null,
  'Great Expectations': null,
};
const getBackToDashboardSurvey = (technologyId: TechnologyId) => {
  const technology = technologyById[technologyId];
  return surveys[technology] || null;
};
export default getBackToDashboardSurvey;
