// Source: https://www.datacamp.com/api/technologies
export const technologyById = {
  1: 'R',
  2: 'Python',
  3: 'SQL',
  4: 'Git',
  5: 'Shell',
  6: 'Google Sheets',
  7: 'Theory',
  8: 'Scala',
  9: 'Tableau',
  10: 'Excel',
  11: 'Power BI',
  12: 'Julia',
  13: 'Docker',
  17: 'Snowflake',
  18: 'Redshift',
  19: 'BigQuery',
  20: 'Airflow',
  21: 'AWS',
  22: 'Databricks',
  23: 'dbt',
  24: 'MLflow',
  25: 'OpenAI',
  26: 'ChatGPT',
  27: 'PyTorch',
  28: 'Spark',
  29: 'Azure',
  30: 'GCP',
  31: 'Kubernetes',
  32: 'DVC',
  33: 'Kafka',
  34: 'Alteryx',
  35: 'Java',
  36: 'Rust',
  37: 'Llama',
  38: 'KNIME',
  39: 'FastAPI',
  40: 'Microsoft Copilot',
  41: 'Github',
  42: 'Great Expectations',
} as const;

export type TechnologyId = keyof typeof technologyById;

export const technology = Object.values(technologyById);
export type Technology = (typeof technology)[number];
