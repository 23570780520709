import type {
  Command,
  SessionOutput,
  SessionStatus,
} from '@datacamp/multiplexer-client';
import { wrap } from 'comlink';
import noop from 'lodash/noop';
import raven from 'raven-js';
// eslint-disable-next-line no-restricted-imports
import { Subject } from 'rxjs';
import type { Subscription } from 'rxjs/Subscription';

import unreachable from '../interfaces/unreachable';
import PyodideWorker from '../workers/pyodide.worker';

import type { CodeExecutionBackend, Subscriber } from './codeExecutionBackend';

export type PyodideAPI = {
  initialise: (initCommand: Command) => Promise<void>;
  runConsoleCommand: (userCode: string) => Promise<string>;
  runSubmitCommand: (userCode: string) => Promise<string>;
};

export const createPyodideBackend = (): CodeExecutionBackend => {
  let clientSubscribers: Array<(data: any) => void> = [];
  const sessionStatusSubscribers: Set<Subscriber<SessionStatus>> = new Set();
  const sessionOutputSubscribers: Set<Subscription> = new Set();

  const output$ = new Subject<SessionOutput[]>();

  const worker: Worker = new PyodideWorker();
  const pyodideAPI = wrap<PyodideAPI>(worker);

  const updateStatus = (status: SessionStatus) => {
    sessionStatusSubscribers.forEach((subscriber) => {
      subscriber(status);
    });
  };

  const runConsoleCommand = async (userCode: string) => {
    const result = await pyodideAPI.runConsoleCommand(userCode);
    output$.next(JSON.parse(result.toString()));
  };

  const runSubmitCommand = async (userCode: string) => {
    const result = await pyodideAPI.runSubmitCommand(userCode);
    output$.next(JSON.parse(result.toString()));
  };

  const runCommand = (command: Command) => {
    try {
      switch (command.command) {
        case 'console':
          return runConsoleCommand(command.code);
        case 'submit':
          return runSubmitCommand(command.code);
        case 'init':
        case 'run':
        case 'soft_init':
        case 'take_solution':
        case 'apply_solution':
        case 'code_completion':
        case 'sendValidationChunk':
        case 'startValidation':
        case 'isValidationDone':
        case 'getValidationResult':
          throw new Error('not implemented');
        default:
          return unreachable(command.command);
      }
    } catch (error) {
      output$.next([
        {
          type: 'error',
          payload: error.toString(),
        },
      ]);
      raven.captureMessage('Pyodide error occurred while running the code', {
        level: 'warning',
        extra: { error },
      });
    }

    return Promise.resolve();
  };

  return {
    client: {
      on: (event, callback) => {
        if (event !== 'new') {
          throw new Error(`unexpected event: ${event}`);
        }
        clientSubscribers.push(callback);
      },
      off: (event) => {
        if (event !== 'new') {
          throw new Error(`unexpected event: ${event}`);
        }
        clientSubscribers = [];
      },
    },
    output$,
    start: async (_, initCommand) => {
      updateStatus({ status: 'busy' });

      try {
        await pyodideAPI.initialise(initCommand);
      } catch (error) {
        raven.captureMessage(
          'Pyodide error occurred while initialising the session',
          {
            level: 'warning',
            extra: { error },
          },
        );
        updateStatus({ status: 'broken' });
        return;
      }

      clientSubscribers.forEach((subscriber) => {
        subscriber({
          body: {
            id: 'pyodide_session',
          },
        });
      });

      updateStatus({ status: 'ready' });
    },

    input: async (command) => {
      updateStatus({ status: 'busy' });
      try {
        await runCommand(command);
      } catch (error) {
        raven.captureMessage('Pyodide error occurred while running the code', {
          level: 'warning',
          extra: { error },
        });
        updateStatus({ status: 'broken' });
        return;
      }
      updateStatus({ status: 'ready' });
    },
    stop: async () => {
      sessionOutputSubscribers.forEach((subscription) =>
        subscription.unsubscribe(),
      );
      sessionOutputSubscribers.clear();

      sessionStatusSubscribers.clear();
    },
    stopPolling: noop,
    subscribe: (subscriber) => {
      sessionStatusSubscribers.add(subscriber);
      return () => {
        sessionStatusSubscribers.delete(subscriber);
      };
    },
    subscribeToOutputs: (
      predicate,
      listener: (output: SessionOutput) => void,
    ) => {
      const filtered = output$.flatMap((outputs) => {
        return outputs.filter(predicate);
      });

      const subscription = filtered.subscribe(listener);

      sessionOutputSubscribers.add(subscription);

      return subscription;
    },
    options: {
      language: 'python',
    },
  };
};
