import { fromJS, Map as hashMap } from 'immutable';
import find from 'lodash/find';

import * as actions from '../actions';

const isChapterReferredInAction = (action: {}, chapter: any) =>
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'chapterRef' does not exist on type '{}'.
  action.chapterRef === chapter.get('slug') ||
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'chapterRef' does not exist on type '{}'.
  Number(action.chapterRef) === Number(chapter.get('id'));

export default (state = hashMap(), action = {}) => {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'type' does not exist on type '{}'.
  switch (action.type) {
    case actions.BOOT_SUCCEEDED: {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'entities' does not exist on type '{}'.
      const { chapters } = action.entities.course;
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'chapterId' does not exist on type '{}'.
      const { chapterId } = action;

      const currentChapterIndex = chapters.findIndex(
        ({ id }: { id: number }) => id === chapterId,
      );
      const isLastChapter = currentChapterIndex === chapters.length - 1;

      const currentChapter = fromJS(find(chapters, { id: chapterId })).set(
        'is_last_chapter',
        isLastChapter,
      );

      return state.mergeDeepIn(['current'], currentChapter);
    }
    case actions.UPDATE_CHAPTER: {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'chapters' does not exist on type '{}'.
      const { chapters } = action;

      const currentChapterIndex = chapters.findIndex((chapter: any) =>
        isChapterReferredInAction(action, chapter),
      );
      const isLastChapter = currentChapterIndex === chapters.size - 1;

      const currentChapter = chapters
        .find((chapter: any) => isChapterReferredInAction(action, chapter))
        .set('is_last_chapter', isLastChapter);

      return state.set('current', currentChapter);
    }
    case actions.FIRST_TIME_COMPLETED_CHAPTERS: {
      const currentChapter = state.getIn(['current', 'id']);
      return state.update(
        'firstTimeCompletedChapters',
        (firstTimeCompletedChapters = hashMap()) =>
          // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
          firstTimeCompletedChapters.set(currentChapter, true),
      );
    }
    case actions.COMPLETE_EXERCISE:
      return state.set('window', fromJS({ type: 'fetching' }));
    case actions.REMOVE_WINDOW:
      return state.setIn(['window', 'show'], false);
    case actions.SHOW_WINDOW:
      return state.setIn(['window', 'show'], true);
    case actions.SHOULD_SHOW_WINDOW:
      return state.set(
        'window',
        fromJS({
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'windowType' does not exist on type '{}'.
          type: action.windowType,
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'windowType' does not exist on type '{}'.
          url: action.url,
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'windowType' does not exist on type '{}'.
          meta: action.meta,
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'windowType' does not exist on type '{}'.
          isCourseEndReached: action.isCourseEndReached ?? false,
        }),
      );
    default:
      return state;
  }
};
