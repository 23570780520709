import type {
  Command,
  ISessionOptions,
  Language,
  SessionOutput,
  SessionStatus,
} from '@datacamp/multiplexer-client';
import type { Observable } from 'rxjs/Observable';
import type { Subscription } from 'rxjs/Subscription';

let codeExecutionBackend: CodeExecutionBackend | undefined;

export type Subscriber<T> = (state: T) => void;
type SubscriptionDeleter = () => void;

export type CodeExecutionBackend = {
  client: {
    off: (event: string) => void;
    on: (event: string, callback: (data: any) => void) => void;
  };
  input: (command: Command) => Promise<void> | void;
  options: {
    language: Language;
  };
  output$: Observable<SessionOutput | SessionOutput[]>;
  start: (
    options: ISessionOptions,
    initCommand: Command,
  ) => Promise<void> | void;
  stop: () => Promise<unknown>;
  stopPolling: () => void;
  subscribe: (subscriber: Subscriber<SessionStatus>) => SubscriptionDeleter;
  subscribeToOutputs: (
    predicate: (outputs: SessionOutput | SessionOutput[]) => boolean,
    listener: (outputs: SessionOutput | SessionOutput[]) => void,
  ) => Subscription;
};

export const getCodeExecutionBackend = (): CodeExecutionBackend | undefined => {
  return codeExecutionBackend;
};

export const setCodeExecutionBackend = (
  newCodeExecutionBackend: CodeExecutionBackend,
): void => {
  codeExecutionBackend = newCodeExecutionBackend;
};
